import styled from 'styled-components';

const PartnerSectionWrapper = styled.section`
  padding: 0;
  background-repeat: no-repeat;
  background-position: 0px 100px;

  .section-idle {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    .gradient-layer {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    }
  }

  img,
  video {
    border-radius: 35px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default PartnerSectionWrapper;

export const style = {
  title: {
    fontStyle: 'normal',
    fontSize: ['45px', '60px', '70px', '80px'],
    fontWeight: 'bold',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: ['30px', '40px'],
    lineHeight: '1.25',
    textAlign: 'left',
  },
  description: {
    fontSize: ['20px', '25px'],
    color: '#fff',
    lineHeight: '1.3',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textAlign: 'left',
  },
};
