import React from 'react';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Text from 'common/components/Text';

import FAQsSectionWrapper, { styles } from './faq.style';

const FAQsSection = () => {
  const { title, titleStyle, questionItem, questionBox } = styles;
  return (
    <FAQsSectionWrapper id="info_section">
      <Box>
        <div className="dots"></div>
        <Heading {...title} content="FAQs" />
      </Box>
      <Box style={questionBox}>
        <Box style={questionItem}>
          <Text {...titleStyle}>
            <span className="emphasize">Q. </span>
            How many simultaneous users can SyncStage support?
          </Text>
          <Text {...titleStyle}>
            <span className="emphasize">A. </span>
            Currently, SyncStage can accommodate up to 8 simultaneous users.
          </Text>
        </Box>

        <Box style={questionItem}>
          <Text {...titleStyle}>
            <span className="emphasize">Q. </span>
            Can users utilize SyncStage when not connected to the 5G network?
          </Text>
          <Text {...titleStyle}>
            <span className="emphasize">A. </span>
            Yes, 5G is not a prerequisite. Users can access SyncStage with any type of internet connection. The quality
            of the experience improves with a better and more stable connection.
          </Text>
        </Box>

        <Box style={questionItem}>
          <Text {...titleStyle}>
            <span className="emphasize">Q. </span>
            Does SyncStage require special hardware to run?
          </Text>
          <Text {...titleStyle}>
            <span className="emphasize">A. </span>
            No, the SyncStage SDK for mobile is compatible with most relatively new Android and iOS devices. However,
            some low-latency capabilities may not be available on older devices. Refer to the platform-specific
            SyncStage{' '}
            <a
              href="https://docs.sync-stage.com/"
              target="_blank"
              style={{ color: 'white', fontWeight: 'bold' }}
              rel="noreferrer"
            >
              documentation
            </a>{' '}
            for detailed information.
          </Text>
        </Box>

        <Box style={questionItem}>
          <Text {...titleStyle}>
            <span className="emphasize">Q. </span>
            How does SyncStage calculate SDK usage?
          </Text>
          <Text {...titleStyle}>
            <span className="emphasize">A. </span>
            SyncStage calculates the usage based on the number of minutes each user is connected to a session.
            Developers can monitor usage through the Developer Console.
          </Text>
        </Box>

        <Box style={questionItem}>
          <Text {...titleStyle}>
            <span className="emphasize">Q. </span>
            How do I pay for SyncStage?
          </Text>
          <Text {...titleStyle}>
            <span className="emphasize">A. </span>
            During the Early Access Phase, developers will receive monthly invoices. The total amount on the invoice
            depends on the usage and the selected plan.
          </Text>
        </Box>
      </Box>
    </FAQsSectionWrapper>
  );
};

export default FAQsSection;
