import React from 'react';
import HeroSection from '../containers/SyncStage/HeroDevelopers';
import GetStartedSection from '../containers/SyncStage/GetStarted';
import FAQsSection from '../containers/SyncStage/FAQs';
import Layout from '../components/layout';

const Developers = () => {
  return (
    <Layout>
      <HeroSection />
      <GetStartedSection />
      <FAQsSection />
    </Layout>
  );
};
export default Developers;
