import React from 'react';
import PropTypes from 'prop-types';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';

import HeroSectionWrapper, { style } from './hero.style';
import PlayableBanner from 'components/Banner/PlayableBanner/PlayableBanner';
import BannerSideText from 'components/Banner/BannerSideText/BannerSideText';
import LogoAnimatedSmall from 'common/assets/image/syncStage/syncstage-logo-animated-frame.webp';
import LazyMedia from 'common/components/LazyMedia/LazyMedia';
import Video from '../../../common/assets/1.mp4';

const HeroSection = () => {
  const { title, description } = style;
  return (
    <HeroSectionWrapper>
      <PlayableBanner
        url={'https://www.youtube.com/watch?v=ghHb7hmMbWc'}
        idle={
          <div className="section-idle">
            <LazyMedia src={Video} loadingSrc={LogoAnimatedSmall} alt="SyncStage Ultra Fast Audio Pipeline" />
            <div className="gradient-layer"></div>
          </div>
        }
      >
        <BannerSideText>
          <div className="contents-layer">
            <Heading {...title}>SYNCSTAGE DEVELOPERS</Heading>
            <Text {...description}>
              <React.Fragment>
                Use SyncStage’s SDKs for Android, iOS and Web to build real-time immersive experiences
              </React.Fragment>
            </Text>
          </div>
        </BannerSideText>
      </PlayableBanner>
    </HeroSectionWrapper>
  );
};

HeroSection.propTypes = {
  title: PropTypes.object,
  description: PropTypes.object,
};

export default HeroSection;
