import styled from 'styled-components';
import FirstBg from 'common/assets/image/syncStage/pattern-1@2x.png';
import SecondBg from 'common/assets/image/syncStage/pattern-2@2x.png';
import ThirdBg from 'common/assets/image/syncStage/pattern-3@2x.png';
import OptimizedBG from 'common/assets/image/bg-optimized.png';
import Dots from 'common/assets/image/syncStage/dots.png';

const InfoSectionWrapper = styled.section`
  padding-inline: 2rem;

  :before {
    content: '';
    background-image: url(${OptimizedBG});
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: cover;

    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
  }

  .dots {
    background-image: url(${Dots});
    background-position: top right;
    background-size: contain;
    background-repeat: no-repeat;
    top: 630px;
    left: 0;
    position: absolute;
    z-index: -1;
    width: 250px;
    height: 100px;

    @media (max-width: 770px) {
      display: none;
    }
  }

  .first-bg {
    background-image: url(${FirstBg});
    background-position: top right;
    background-size: contain;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 800px;
  }

  .second-bg {
    background-image: url(${SecondBg});
    background-position: top right;
    background-size: contain;
    background-repeat: no-repeat;
    top: 800px;
    left: 0;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 800px;
  }

  .third-bg {
    background-image: url(${ThirdBg});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    top: 1600px;
    left: 0;
    position: absolute;
    z-index: -1;
    width: 100%;
  }

  .email-us {
    font-size: 22px;
    color: white;
    margin-bottom: 60px;

    span {
      color: #36dbfd;
    }
  }
  .two-col-grid {
    display: flex;
    justify-content: center;
  }

  .sticky-col {
    position: sticky;
    top: 100px;
    align-self: self-start;

    .image-wrapper img {
      max-width: 90px;
      height: 100%;
    }
    @media (max-width: 575px) {
      padding: 20px;
    }
  }

  .scroll-col {
    width: 30%;
    margin-left: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    @media (max-width: 990px) {
      width: 50%;
      margin-left: 100px;
    }
    @media (max-width: 767px) {
      width: 75%;
      margin-left: 50px;
    }
    @media (max-width: 575px) {
      width: 905%;
      margin: 0 20px;
    }
  }

  .info-item {
    margin: 100px 0;
    &.last {
      margin: 100px 0 200px 0;
    }
    @media (max-width: 990px) {
      margin: 70px 0;
      &.last {
        margin: 70px 0 140px 0;
      }
    }
    @media (max-width: 767px) {
      margin: 50px 0;
      &.last {
        margin: 50px 0 100px 0;
      }
    }
    @media (max-width: 575px) {
      margin: 30px 0;
      &.last {
        margin: 50px 0 100px 0;
      }
    }
  }
`;

export default InfoSectionWrapper;

export const styles = {
  title: {
    fontSize: ['34px', '42px', '44px', '48px'],
    fontWeight: 'bold',
    color: '#fff',
    letterSpacing: '-0.025em',
    pt: ['60px', '80px', '100px', '150px', '150px'],
    pb: ['30px', '40px', '50px', '75px', '75px'],
    lineHeight: '1',
    textAlign: 'center',
    fontStyle: 'normal',
  },
  questionBox: {
    maxWidth: '620px',
    textAlign: 'left',
    margin: 'auto',
    paddingBottom: '50px',
  },
  questionItem: {
    marginBottom: '80px',
  },
  titleStyle: {
    color: 'white',
    lineHeight: '33px',
    fontSize: '22px',
  },
};
