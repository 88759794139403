import React from 'react';

import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';

import GetStartedSectionWrapper, { styles } from './getstarted.style';
import Image from 'common/components/Image';
import card1 from 'common/assets/image/syncStage/card-android.png';
import card2 from 'common/assets/image/syncStage/card-ios.png';
import webSdk from 'common/assets/image/syncStage/card-web-sdk.png';

const GetStartedSection = () => {
  const { row, title, contentWrapper, textStyle, icon } = styles;
  return (
    <GetStartedSectionWrapper>
      <Container>
        <Box {...row}>
          <Box {...contentWrapper}>
            <Heading {...title} content="Getting Started" />
          </Box>
        </Box>
        <Box className="text" {...row}>
          <Box>
            <Text {...textStyle}>
              SyncStage is now accessible through Early Access. To begin utilizing the SDK, please complete&nbsp;
              <a href={process.env.REQUEST_DEMO_URL} className="emphasize">
                this form
              </a>
              &nbsp;to request your Early Access Code. We will reach out to you shortly to provide you with the
              necessary information.
            </Text>
          </Box>
          <div className="platforms">
            <a href="https://docs.sync-stage.com/android/overview/" target="_blank" rel="noreferrer">
              <Image id="github-android" src={card1} style={icon} />
            </a>
            <a href="https://docs.sync-stage.com/ios/overview/" target="_blank" rel="noreferrer">
              <Image id="github-ios" src={card2} style={icon} />
            </a>
            <a href="https://docs.sync-stage.com/web/overview/" target="_blank" rel="noreferrer">
              <Image id="web-sdk" src={webSdk} style={icon} />
            </a>
          </div>
        </Box>
      </Container>
    </GetStartedSectionWrapper>
  );
};

export default GetStartedSection;
